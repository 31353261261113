import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './formatting.css';
import { blockEvent } from '../../../../services/helpers';
import ScrollBar from '../../../../components/scrollBar/ScrollBar';
import BIH from './BIH';
import ParagraphOptions from './ParagraphOptions';
import { transS } from '../../../../services/helpers/lang';
import StylesBlock from './StylesBlock'
import FontOptions from './FontOptions';

const fSize = s => +s.replace('px', '')

const Formatting = () => {
  const {formatting, frameProgress, isInch} = useSelector(state => state.paragraphReducer)
  const {fonts} = useSelector(state => state.document)
  const sendToFrame = frameProgress?.sendToFrame
  const fontSize = !formatting ? '--' :
    Math.round(75 * fSize(formatting.fontSize) 
      / fSize(getComputedStyle(document.body.parentElement).fontSize)) / 10

  const pxToPt = px => Math.round(px.slice(0, px.length - 2)*0.75) 
  const pxToCm = px => isInch ? Math.round(px.slice(0, px.length - 2)/96 * 10)/10 : Math.round(px.slice(0, px.length - 2)/38 * 100)/100
  const makeLineSpacing = (fontSize, lineHeight) => Math.round(100*(lineHeight.slice(0, lineHeight.length - 2)/fontSize.slice(0, fontSize.length - 2)))/100

  const MOCK_STYLES = [
    {
      // main: true,
      id: "c1c1",
      name: "Заголовок 1",
      params: {
        align: "start",
        before: 18,
        after: 4,
        left: 0,
        right: 0,
        hanging: 0,
        firstLine: 0,
        line: 1.5,
        bold: false,
        italic: false,
        fonts: fonts,
        currentFont: "Aptos Display",
        fontSize: 20,
    }
  },
  {
    name: "Заголовок 2",
    id: "c1c2",
    params: {
      align: "start",
      before: 8,
      after: 4,
      left: 0,
      right: 0,
      hanging: 0,
      firstLine: 0,
      line: 1.5,
      bold: false,
      italic: false,
      fonts: fonts,
      currentFont: "Aptos Display",
      fontSize: 16,
    }
  },
  {
    name: "Заголовок 3",
    id: "c1c3",
    params: {
      align: "start",
      before: 8,
      after: 4,
      left: 0,
      right: 0,
      hanging: 0,
      firstLine: 0,
      line: 1.5,
      bold: false,
      italic: false,
      fonts: fonts,
      currentFont: "Aptos Display",
      fontSize: 14,
    }
  },
  {
    name: "Заголовок 4",
    id: "c1c4",
    params: {
      align: "start",
      before: 4,
      after: 2,
      left: 0,
      right: 0,
      hanging: 0,
      firstLine: 0,
      line: 1.5,
      bold: false,
      italic: true,
      fonts: fonts,
      currentFont: "Aptos Display",
      fontSize: 11,
    }
  },
  {
    name: "Заголовок 5",
    id: "c1c5",
    params: {
      align: "start",
      before: 4,
      after: 2,
      left: 0,
      right: 0,
      hanging: 0,
      firstLine: 0,
      line: 1.5,
      bold: false,
      italic: false,
      fonts: fonts,
      currentFont: "Aptos Display",
      fontSize: 11,
    }
  },
  {
    name: "Заголовок 6",
    id: "c1c6",
    params: {
      align: "start",
      before: 2,
      after: 0,
      left: 0,
      right: 0,
      hanging: 0,
      firstLine: 0,
      line: 1.5,
      bold: false,
      italic: true,
      fonts: fonts,
      currentFont: "Aptos Display",
      fontSize: 11,
    }
  },
  {
    name: "Заголовок 7",
    id: "c1c7",
    params: {
      align: "start",
      before: 2,
      after: 0,
      left: 0,
      right: 0,
      hanging: 0,
      firstLine: 0,
      line: 1.5,
      bold: false,
      italic: false,
      fonts: fonts,
      currentFont: "Aptos Display",
      fontSize: 11,
    }
  },
  {
    name: "Заголовок 8",
    id: "c1c8",
    params: {
      align: "start",
      before: 0,
      after: 0,
      left: 0,
      right: 0,
      hanging: 0,
      firstLine: 0,
      line: 1.5,
      bold: false,
      italic: true,
      fonts: fonts,
      currentFont: "Aptos Display",
      fontSize: 11,
    }
  },
  {
    name: "Заголовок 9",
    id: "c1c9",
    params: {
      align: "start",
      before: 0,
      after: 0,
      left: 0,
      right: 0,
      hanging: 0,
      firstLine: 0,
      line: 1.5,
      bold: false,
      italic: false,
      fonts: fonts,
      currentFont: "Aptos Display",
      fontSize: 11,
    }
  },
  {
    id: "c1c22",
    name: "Заголовок",
    params: {
      align: "start",
      before: 0,
      after: 4,
      left: 0,
      right: 0,
      hanging: 0,
      firstLine: 0,
      line: 1.5,
      bold: false,
      italic: false,
      fonts: fonts,
      currentFont: "Aptos Display",
      fontSize: 28,
    }
  },
  {
    id: "c1c24",
    name: "Подзаголовок",
    params: {
      align: "start",
      before: 0,
      after: 8,
      left: 0,
      right: 0,
      hanging: 0,
      firstLine: 0,
      line: 1.5,
      bold: false,
      italic: false,
      fonts: fonts,
      currentFont: "Aptos",
      fontSize: 14,
    }
  },
  {
    id: "c1c26",
    name: "Цитата 2",
    params: {
      align: "center",
      before: 8,
      after: 8,
      left: 0,
      right: 0,
      hanging: 0,
      firstLine: 0,
      line: 1.5,
      bold: false,
      italic: true,
      fonts: fonts,
      currentFont: "Aptos",
      fontSize: 11,
    }
  },
  {
    id: "c1c30",
    name: "Выделенная цитата",
    params: {
      align: "center",
      before: 18,
      after: 18,
      left: 1.5,
      right: 1.5,
      hanging: 0,
      firstLine: 0,
      line: 1.5,
      bold: false,
      italic: true,
      fonts: fonts,
      currentFont: "Aptos",
      fontSize: 11,
    }
  },
  ]

  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false)
  const [isMoreStylesOpen, setIsMoreStylesOpen] = useState(false)
  const [activeStyles, setActiveStyles] = useState(null)
  const [stylesFilter, setStylesFilter] = useState("All")
  const [mockStyles, setMockStyles] = useState(MOCK_STYLES)
  const [mainStyle, setMainStyle] = useState(null)
  
  const currentFont = formatting
    ? formatting.fontFamily.split(",")[0].replaceAll('"', "")
    : "---"

  const isBold = formatting?.fontWeight === 'bold',
    isItalic = formatting?.fontStyle === 'italic'

  useEffect(() => {
    if (!!formatting) {
      setMockStyles(() => {
        const temp = MOCK_STYLES.map(item => {
            if(item.id === formatting.paraStyle.split(" ")[0]) {
              return {...item, main: true}
            } else {
              return {...item}
            }
        })
        const findMain = temp.filter(item => item.main)
        setMainStyle(findMain.length ? findMain[0] : null)
        return temp
      }
    )
    }
  }, [formatting])

  return (
    <div styleName="wrapper">
      {!formatting && (
        <div styleName="screen" onMouseDown={(e) => blockEvent(e)}></div>
      )}
      {/* <div styleName="row centered">
        <div styleName="fontPicker  clickable" ref={pickerRef}>
          <div styleName={`bordered${fontPopupOpen ? ' opened' : ''}`}
           onClick={() => setFontPopupOpen(!fontPopupOpen)}>
            <span>{currentFont}</span>
            <div className={"arrow" + (fontPopupOpen ? " arrowOpen" : "")} 
              styleName="shifted5"></div>
            {fontPopupOpen && !!formatting && (
              <div styleName="font_popup" ref={popupRef}
                  style={{height:`min(220px, ${fonts.length * 22 - 15}px`}}>
                <ScrollBar
                  style={{ height: "100%", width: "calc(100% + 0px)" }}>
                  {fonts.map((el) => (el !== currentFont) && (
                    <div styleName="font-line"
                      key={el}
                      onClick={() => {
                        sendToFrame({c1: "format", font: el})
                        setFontPopupOpen(false)
                      }}>
                      {el}
                    </div>
                  ))}
                </ScrollBar>
              </div>
            )}
          </div>
        </div>
        <div styleName="sizePicker bordered">
          <span styleName="sign minus clickable"
            onClick={() =>
              sendToFrame({
                c1: "format",
                format: { fontSize: decreaseFont(true)}})}>-</span>
          {fontSize}
          <span styleName="ptClass">pt</span>
          <span styleName="sign minus clickable"
            onClick={() =>
              sendToFrame({
                c1: "format",
                format: { fontSize: decreaseFont()}})}>+</span>
        </div>  
        <BIH sendToFrame={sendToFrame}  />
      </div> */}
      <FontOptions fontSize={fontSize} currentFont={currentFont} fonts={fonts} sendToFrame={sendToFrame} isFormatting={formatting} isBold={isBold} isItalic={isItalic} sendRems/>

      <div styleName="row marg25">
        <p className='h4'>{transS('Paragraph')}</p>
        <div styleName="margl20 prima clickable" onClick={() => setIsMoreOptionsOpen(prev => !prev)}>
          {isMoreOptionsOpen ? transS('Less options') : transS('More options')}
        </div>
      </div>
      {isMoreOptionsOpen && <ParagraphOptions params={{
        before: (formatting ? pxToPt(formatting.marginTop) : "--"),
        after: (formatting ? pxToPt(formatting.marginBottom) : "--"),
        left: (formatting ? Math.round(100*(pxToCm(formatting.marginLeft) + (pxToCm(formatting.textIndent) < 0 ? pxToCm(formatting.textIndent) : 0)))/100 : "--"),
        right: (formatting ? pxToCm(formatting.marginRight) : "--"),
        hanging: (formatting ? (pxToCm(formatting.textIndent) < 0 ? -pxToCm(formatting.textIndent) : 0) : "--"),
        firstLine: (formatting ? (pxToCm(formatting.textIndent) > 0 ? pxToCm(formatting.textIndent) : 0) : "--"),
        line: (formatting ? makeLineSpacing(formatting.fontSize, formatting.lineHeight) : "--")
      }} sendToFrame={sendToFrame} sendRems/>}
      <div styleName="row marg25">
        <p className='h4'>{transS('Styles')}</p>
        <div styleName="margl20 prima clickable" onClick={() => setIsMoreStylesOpen(prev => !prev)}>
          {isMoreStylesOpen ? transS('Less styles') : transS('More styles')}
        </div>
        {isMoreStylesOpen &&
        <div styleName="stylesBtns">
          <div styleName={"stylesBtn" + (stylesFilter === "All" ? " active" : "")} onClick={() => {setStylesFilter("All")}}>{transS("All")}</div>
          <div styleName={"stylesBtn" + (stylesFilter === "Only" ? " active" : "")} onClick={() => {setStylesFilter("Only")}}>{transS("Only Used Here")}</div>
        </div>}
      </div>
      {isMoreStylesOpen &&
          <>
            {!!mainStyle && <StylesBlock params={mainStyle} isMain={true}/>}
            <div styleName="stylesContainer">
                {mockStyles.map((item, index) => {
                    return (
                    <div key={index} onClick={() => {setActiveStyles((prev) => (index === prev ? null : index))}}>
                      <StylesBlock params={item} active={activeStyles === index} setActiveStyles={setActiveStyles}/>
                    </div>)
                  })}
            </div>
          </>
      }
      {/* <div styleName='row marg10'>
        <div styleName='headingContainer'>
          <div styleName='heading'>Align</div>
          <div styleName="svgIcons clickable">
            <div onClick={() => doAdjust(0)}>{leftAlign(textAlignNumber === 0)}</div>
            <div onClick={() => doAdjust(1)}>{centerAlign(textAlignNumber === 1)}</div>
            <div onClick={() => doAdjust(2)}>{rightAlign(textAlignNumber === 2)}</div>
            <div onClick={() => doAdjust(3)}>{justifyAlign(textAlignNumber === 3)}</div>
          </div>
        </div>
        <div styleName='headingContainer'>
          <div styleName='heading'>Before</div>
          <div styleName='flexRow'>
            <div styleName="changeIndent bordered">
              <span styleName="sign minus clickable"
                onClick={() =>{}}>-</span>
              {fontSize}
              <span styleName="ptClass">pt</span>
              <span styleName="sign minus clickable"
                onClick={() =>{}}>+</span>
            </div>  
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default new CSSModules(Formatting, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
