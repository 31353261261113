import React, { useEffect, useRef, useState } from 'react';
import CSSModules from 'react-css-modules';
import styles from "./formatting.css"
import { transS } from '../../../../services/helpers/lang';
import ScrollBar from '../../../../components/scrollBar/ScrollBar';
import BIH from './BIH';

const FontOptions = ({fontSize, currentFont, fonts, sendToFrame, isFormatting, isBold, isItalic, sendRems}) => {

    const popupRef = useRef(null)
    const pickerRef = useRef(null)

    const [fontPopupOpen, setFontPopupOpen] = useState(false)

    const decreaseFont = (isDecrease) => {
        let size
        if (isDecrease) {
        if (fontSize < 4) return
        size = fontSize <= 8 ? fontSize - 0.5 : fontSize - 1
        } else
        size = fontSize < 8 ? fontSize + 0.5 : fontSize + 1
        const remSize = (size/7.5).toFixed(3)
        if (sendRems) {
          return remSize < 1.6 ? `max(var(--fscale), ${remSize}rem)` : `${remSize}rem`
        }
        return size
    }
    
    const clickOutsideHandler = ({target}) => {
        if (popupRef.current && !(
            popupRef.current.contains(target) || 
            pickerRef.current.contains(target)) )
            setFontPopupOpen(false);
    };
    
    useEffect(() => {
        window.addEventListener('click', clickOutsideHandler);
        return () => {
        window.removeEventListener('click', clickOutsideHandler);
        };
    }, []);

    return (
        <div styleName="row centered">
        <div styleName="fontPicker  clickable" ref={pickerRef}>
          <div styleName={`bordered${fontPopupOpen ? ' opened' : ''}`}
           onClick={() => setFontPopupOpen(!fontPopupOpen)}>
            <span>{currentFont}</span>
            <div className={"arrow" + (fontPopupOpen ? " arrowOpen" : "")} 
              styleName="shifted5"></div>
            {fontPopupOpen && !!isFormatting && (
              <div styleName="font_popup" ref={popupRef}
                  style={{height:`min(220px, ${fonts.length * 22 - 15}px`}}>
                <ScrollBar
                  style={{ height: "100%", width: "calc(100% + 0px)" }}>
                  {fonts.map((el) => (el !== currentFont) && (
                    <div styleName="font-line"
                      key={el}
                      onClick={() => {
                        sendToFrame({c1: "format", font: el})
                        setFontPopupOpen(false)
                      }}>
                      {el}
                    </div>
                  ))}
                </ScrollBar>
              </div>
            )}
          </div>
        </div>
        <div styleName="sizePicker bordered">
          <span styleName="sign minus clickable"
            onClick={() =>
              sendToFrame({
                c1: "format",
                format: { fontSize: decreaseFont(true)}})}>-</span>
          {fontSize}
          <span styleName="ptClass">{transS("pt")}</span>
          <span styleName="sign minus clickable"
            onClick={() =>
              sendToFrame({
                c1: "format",
                format: { fontSize: decreaseFont()}})}>+</span>
        </div>  
        <BIH sendToFrame={sendToFrame} isBold={isBold} isItalic={isItalic}/>
      </div>
    );
};

export default new CSSModules(FontOptions, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
