import keyMirror from 'keymirror';
import path from 'path';
import dotEnv from 'dotenv';
import fs from 'fs';
dotEnv.config();

const NODE_ENV = process.env.NODE_ENV || 'production';
export const validFileExtensions = ['.jpg', '.jpeg', '.png'];

export const parserConstants = {
  UNOCONV_UNIX: false
};
export const shdColors = ["#ffffff", "#feff06", "#faab67", "#ff6c6c", "#74ff6f", 
"#affafa", "#ffabff", "#a296ff", "#acacac", "#dedede"];

export const standartTextColors = [
  "#000000", // Черный
  "#1F497D", // Темно-синий
  "#4F6228", // Темно-зеленый
  "#632423", // Темно-бордовый
  "#3F3151", // Темно-фиолетовый
  "#31859C", // Темно-голубой
  "#4B5320", // Оливковый
  "#808080", // Серый-80%
  "#0070C0", // Голубой
  "#00B050", // Зеленый
  "#FF0000", // Красный
  "#7030A0", // Фиолетовый
  "#00B0F0", // Бирюзовый
  "#FFC000", // Оранжевый
  "#C0C0C0", // Серый-50%
  "#92CDDC", // Светло-голубой
  "#9BBB59", // Светло-зеленый
  "#FABF8F", // Светло-оранжевый
  "#FFF2CC", // Светло-желтый
  "#FFFFFF"  // Белый
];

export const htmlGeneratorConstants = {
  TEMPLATE_PATH: path.join(__dirname, '..', '/server', '/services', '/converter', '/template.hbs'),
  // RESULT_PATH: path.join(__dirname, '..', '/server', '/services', '/converter')
  RESULT_PATH: path.join(__dirname, '..', '/generated_files'),
  EXPERIMENTAL_PATH: path.join(__dirname, '..', '/server', '/experimental', '/experimental.json')
};

let experimental_content = null;
const _setExperimentalContent = () => {
  try {
    console.log('experimental = ', htmlGeneratorConstants.EXPERIMENTAL_PATH);
    if (fs.statSync(htmlGeneratorConstants.EXPERIMENTAL_PATH, {throwIfNoEntry: false})) {
      const xx = fs.readFileSync(htmlGeneratorConstants.EXPERIMENTAL_PATH, {encoding: 'utf8'}).replace(/^\uFEFF/, "").replace(/^&#xFEFF;/, "");
      experimental_content = JSON.parse(xx);
    }
  }
  catch (err) { console.log('experimental content', err);};
};

export const socketConstants = keyMirror({
  JOIN_TO_GROUP: null,
  STOP_PUBLICATION_TIMER: null,
  
  START_EDITING_PARAGRAPH: null,
  UPDATE_EDITING_PARAGRAPH: null,
  UPDATE_TEMPLATE: null,
  
  ADD_DOCUMENT: null,
  NEW_DOCUMENT_SUBVERSION: null,
  NEW_DOCUMENT_VERSION: null,
  GET_DOCUMENT_BY_FINALIZE: null,
  GET_DOCUMENT_BY_REQUEST: null,
  GET_DOCUMENT_BY_FORCE: null,
  DOCUMENT_DELETED: null,
  ENVELOPE_CHANGED: null,
  ENTER_THE_DOCUMENT: null,
  APPROVE_DOCUMENT: null,
  ENTER_THE_PROJECT_LIST: null,
  ENTER_THE_PROJECT: null,
  PROJECT_DELETED: null,
  PROJECT_NAME_UPDATE: null,
  USER_EXPELLED: null,
  USER_TRANSFERRED_TO_COUNTER_PARTY: null,
  INTRODUCTION: null,
  CONCLUSION: null,
  UPDATE_APPROVE_MESSAGE: null,
  UPDATE_REPLY_APPROVE_MESSAGE: null,
  UPDATE_DOCUMENT_MESSAGES: null,
  UPDATE_USER_INTERFACE_DATA: null,
  GET_UNKNOWNS: null,
  WRITING_COVER_MESSAGE: null,
  DOCUMENT_CHANGE: null,
  UPDATE_PROJECT: null,
  UPDATE_CLAUSE: null,
});

export const cookiesConstants = {
  COOKIE_TOKEN_KEY: 'COOKIE_TOKEN_KEY',
  COOKIE_HEADER_KEY: 'X-Auth-Token'
};

export const avatarFolder = path.join(__dirname, '..', '/uploads', '/avatars');

export const allowedDocumentFormats = ['doc', 'docx', 'odt'];

export const notifications = {
  someoneDeleted: 'Some users have been removed from the project! If you save, they will lose the access to the project.'
};

export const errorMessages = {
  loginError: 'User not found.',
  loginActivationError: 'User not activated.',

  signUpError: 'Sign up error.',
  signUpInvalidDataError: 'Invalid user data',
  signUpUserExistsError: 'A user with such an email exists.',
  signUpSendEmailError: 'Error when sending a message.',

  activationError: 'Activation error',
  activationUserError: 'Activation link is not valid',

  invitationError: 'Invitation error',
  invitationExistsError: 'This user is already invited to this project',
  invitationSendEmailError: 'Sending the invitation message failed',
  invitationProjectNotExistsError: 'Project does not exist',

   
  
  invalidFormat: 'Invalid format!',
  overSize: 'The photo can not be more than 2Mb.',

  incorrectPassword: 'Incorrect password.',

  invalidAvatar: 'Square image 80х80 px',

  
  
  
  
  
  isFreePlanEmail: 'The user with FREE plan is not allowed to be in the internal group',
  
  
  errorPassword: 'Password is not correct.',
  

  addProjectError: 'Add new project error.',

  getProjectsError: 'Get projects error.',

  renameProjectError: 'Rename project error',
  deleteProjectError: 'Delete project error',
  noRenameProjectPermission: 'Permission to rename the project denied',
  noCreateProjectPermission: 'Permission to create the project denied for free users',
  noOpenProjectPermission: 'Permission to open the project denied for free users',
  noDeleteProjectPermission: 'Permission to delete the project denied',
  updateUserInfoError: 'Can not update info',
  linkInconsistent: 'Link to project is inconsistent',

  addDocumentAllowedDocumentFormatError: 'Allowed document in add new document error.',
  addDocumentFindProjectError: 'Find project in add new document error.',
  addDocumentProjectNotFoundFormatError: 'Project not found in add new document error.',
  addDocumentFindUserGroupError: 'Find user group in add new document error.',
  addDocumentError: 'Add new document error.',
  addDocumentSaveFileError: 'Save file in add new document error.',
  addDocumentParseFileError: 'Parse in add new document error.',
  addDocumentSaveError: 'Save in add new document error.',
  addDocumentDeleteFileError: 'Delete file in add new document error.',
  addDocumentForbiddenError: 'Forbidden in add new document error.',

  uploadDocumentOversizeError: 'The document is oversized. Please upload a file under 20 MB.',
  uploadDocumentFormatError: 'Please upload a doc or docx file. (It seems you uploaded something else.)',
  uploadDocumentEmptyError: "Please upload a doc or docx file. (It seems you didn't attach anything.)",

  noRenameDocumentPermission: 'Permission to rename document denied',
  noDeleteDocumentPermission: 'Permission to delete document denied',
  noDownloadDocumentPermission: 'Permission to download document denied',

  deleteDocumentError: 'Can not delete document',
  renameDocumentError: 'Can not rename document',

  
  getDocumentsBadQueryError: 'Get documents bad query error.',
  getDocumentsFindProjectError: 'Find project get documents user group error.',
  getDocumentsProjectNotFoundFormatError: 'Project not found get documents user group error.',
  getDocumentsUserGroupError: 'Get documents user group error.',
  getDocumentsUsersInUserGroupError: 'Get documents users in user group error.',
  getDocumentsError: 'Get documents error.',
  getDocumentsLastCommentError: 'Get documents last comment error',

  getDocumentBadQueryError: 'Get document bad query error.',
  getDocumentFindProjectError: 'Find project in get document error.',
  getDocumentProjectNotFoundFormatError: 'Project not found in get document error.',
  getDocumentFindUserGroupError: 'Find user group in get document error.',
  getDocumentError: 'Get document error.',
  getDocumentNotFoundError: 'Document not found error.',
  getDocumentForbiddenError: 'Get document forbidden error.',

  readDocumentBadQueryError: 'Read document bad query error.',
  readDocumentFindProjectError: 'Find project in read document error.',
  readDocumentProjectNotFoundFormatError: 'Project not found in read document error.',
  readDocumentFindUserGroupError: 'Find user group in read document error.',
  readDocumentError: 'Read document error.',
  readDocumentNotFoundError: 'Read document not found error.',
  readDocumentForbiddenError: 'Read document forbidden error.',
  readDocumentSaveViewedByError: 'Read document save viewdBy error.',

  getParagraphsBadQueryError: 'Bad query in get paragraphs error.',
  getParagraphsFindProjectError: 'Find project in get paragraphs error.',
  getParagraphsProjectNotFoundFormatError: 'Project not found in get paragraphs error.',
  getParagraphsFindDocumentError: 'Find document in get paragraphs error.',
  getParagraphsDocumentNotFoundFormatError: 'Document not found in get paragraphs error.',
  getParagraphsError: 'Get paragraphs error.',
  getParagraphsUserGroupError: 'Get paragraphs user group error.',
  getParagraphsForbiddenError: 'Forbidden in get paragraphs error.',

  getParagraphByIdBadQueryError: 'Bad query in get paragraph by id error.',
  getParagraphByIdFindProjectError: 'Find project in get paragraph by id error.',
  getParagraphByIdProjectNotFoundFormatError: 'Project not found in get paragraph by id error.',
  getParagraphByIdFindDocumentError: 'Find document in get paragraph by id error.',
  getParagraphByIdDocumentNotFoundFormatError: 'Document not found in get paragraph by id error.',
  getParagraphByIdError: 'Get paragraph by id error.',
  getParagraphByIdUserGroupError: 'Get paragraph by id user group error.',
  getParagraphByIdNotFoundError: 'Paragraph not found in get paragraph by id error.',
  getParagraphByIdForbiddenError: 'Forbidden in get paragraph by id error.',

  addNewParagraphFindProjectError: 'Find project in add paragraph error.',
  addNewParagraphProjectNotFoundFormatError: 'Project not found in add paragraph error.',
  addNewParagraphFindDocumentError: 'Find document in add paragraph error.',
  addNewParagraphDocumentNotFoundFormatError: 'Document not found in add paragraph error.',
  addNewParagraphError: 'Add paragraph error.',
  addNewParagraphUserGroupError: 'Add paragraph user group error.',
  addNewParagraphForbiddenError: 'Forbidden in add paragraph error.',

  editingParagraphFindProjectError: 'Find project in editing paragraph error.',
  editingParagraphProjectNotFoundFormatError: 'Project not found in editing paragraph error.',
  editingParagraphFindDocumentError: 'Find document in editing paragraph error.',
  editingParagraphDocumentNotFoundFormatError: 'Document not found in editing paragraph error.',
  editingParagraphError: 'Editing paragraph error.',
  editingParagraphUserGroupError: 'Editing paragraph user group error.',
  editingEditingParagraphNotFoundError: 'Paragraph not found in editing paragraph error.',
  editingParagraphForbiddenError: 'Forbidden in editing paragraph error.',

  updateEditingParagraphFindProjectError: 'Find project in update paragraph error.',
  updateEditingParagraphProjectNotFoundFormatError: 'Project not found in update paragraph error.',
  updateEditingParagraphFindDocumentError: 'Find document in update paragraph error.',
  updateEditingParagraphDocumentNotFoundFormatError: 'Document not found in update paragraph error.',
  updateEditingParagraphError: 'Update paragraph error.',
  updateEditingParagraphUserGroupError: 'Update paragraph user group error.',
  updateEditingParagraphNotFoundError: 'Paragraph not found in update paragraph error.',
  updateEditingParagraphForbiddenError: 'Forbidden in update paragraph error.',

  deleteParagraphByIdBadQueryError: 'Bad query in delete paragraph by id error.',
  deleteParagraphFindProjectError: 'Find project in delete paragraph error.',
  deleteParagraphProjectNotFoundFormatError: 'Project not found in delete paragraph error.',
  deleteParagraphFindDocumentError: 'Find document in delete paragraph error.',
  deleteParagraphDocumentNotFoundFormatError: 'Document not found in delete paragraph error.',
  deleteParagraphError: 'Delete paragraph error.',
  deleteParagraphUserGroupError: 'Delete paragraph user group error.',
  deleteParagraphNotFoundError: 'Paragraph not found in delete paragraph error.',
  deleteParagraphForbiddenError: 'Forbidden in delete paragraph error.',
  deleteParagraphsBadQueryError: 'Forbidden in delete paragraph error.',

  parseFileError: 'Parse file error.',

  getDocumentByRequestAskBadQueryFormatError: 'Bad query format in get document by request ask.',
  getDocumentByRequestAskFindProjectError: 'Find project in get document by request ask.',
  getDocumentByRequestAskProjectNotFoundFormatError: 'Project not found in get document by request ask.',
  getDocumentByRequestAskFindUserGroupError: 'Find user group in get document by request ask.',
  getDocumentByRequestAskError: 'Error in get document by request ask.',
  getDocumentByRequestAskNotFoundError: 'Not found in get document by request ask.',
  getDocumentByRequestAskForbiddenError: 'Forbidden in get document by request ask.',
  getDocumentBadQueryFormatError: 'Bad query format in get document by request.',
  getDocumentByRequestAskUpRequestError: 'Setup request for editing save failed in get document by request.',

  getDocumentByRequestBadQueryFormatError: 'Bad query format in get document by request.',
  getDocumentByRequestFindProjectError: 'Find project in get document by request.',
  getDocumentByRequestProjectNotFoundFormatError: 'Project not found in get document by request.',
  getDocumentByRequestFindUserGroupError: 'Find user group in get document by request.',
  getDocumentByRequestError: 'Error in get document by request.',
  getDocumentByRequestNotFoundError: 'Not found in get document by request.',
  getDocumentByRequestForbiddenError: 'Forbidden in get document by request.',
  getDocumentByRequestUpVersionError: 'Up version save failed in get document by request.',
  getDocumentByRequestCloneError: 'Clone failed in get document by request.',
  getDocumentByRequestSetDownRequestError: 'Down request save failed in get document by request.',

  saveDocumentSubVersionBadQueryError: 'Bad query in save document subversion.',
  saveDocumentSubVersionFindProjectError: 'Find project in save document subversion.',
  saveDocumentSubVersionProjectNotFoundFormatError: 'Project not found save get document subversion.',
  saveDocumentSubVersionFindUserGroupError: 'Find user group in save document subversion.',
  saveDocumentSubVersionError: 'Error in save document subversion.',
  saveDocumentSubVersionNotFoundError: 'Not found in save document subversion.',
  saveDocumentSubVersionForbiddenError: 'Forbidden in save document subversion.',
  saveDocumentSubVersionUpSubVersionError: 'Save up subVersion save failed in save document subversion.',
  saveDocumentSubVersionCloneError: 'Clone for subVersion failed in save document subversion.',

  getDocumentByForceBadQueryFormatError: 'Bad query format in get document by force.',
  getDocumentByForceFindProjectError: 'Find project in get document by force.',
  getDocumentByForceProjectNotFoundFormatError: 'Project not found in get document by force.',
  getDocumentByForceFindUserGroupError: 'Find user group in get document by force.',
  getDocumentByForceError: 'Error in get document by force.',
  getDocumentByForceNotFoundError: 'Not found in get document by force.',
  getDocumentByForceForbiddenError: 'Forbidden in get document by force.',
  getDocumentByForceSetUpSubVersionError: 'Setup subVersion save failed clone document in get document by force.',
  getDocumentByForceCloneDocumentError: 'Failed clone document in get document by force.'
};


export default {
  NODE_ENV,
  commentPublicationWaitingTime: parseInt(process.env.COMMENT_PUBLICATION_WAITING_TIME),
  paragraphChangesCheckingTime: process.env.PARAGRAPH_CHANGES_CHEKING_TIME,
  paragraphChangeSavingTime: parseInt(process.env.PARAGRAPH_CHANGES_SAVING_TIME),
  paragraphLastChangesCheckingTime: parseInt(process.env.PARAGRAPH_LAST_CHANGES_CHEKING_TIME),
  projectName: process.env.PROJECT_NAME,
  secret: process.env.SECRET,
  secretToken: process.env.SECRET_TOKEN,
  mailTokenKey: process.env.MAIL_TOKEN_KEY,
  emailForAuth: process.env.EMAIL_FOR_AUTH,
  emailForAuthPass: process.env.EMAIL_FOR_AUTH_PASS,
  emailSMTP: process.env.EMAIL_SMTP,
  emailUser: process.env.EMAIL_USER,
  emailPort: process.env.EMAIL_PORT,
  emailSecure: process.env.EMAIL_SECURE,
  ignoreTLS: process.env.EMAIL_IGNORE_TLS,
  emailInfo: process.env.EMAIL_INFO,
  emailInfoBCC: process.env.EMAIL_INFO_BCC,
  devPort: process.env.DEV_PORT,
  stonedHtml: process.env.STONED_HTML,
  stonedHtml1: process.env.STONED_HTML1,
  stonedHtmlIncluded: process.env.STONED_HTML_INCLUDED,
  unoconvOnUnix: process.env.UNOCONV_ON_UNIX,
  customHost: process.env.CUSTOM_HOST,
  isSecure: process.env.IS_SECURE,
  completeHost: `${process.env.IS_SECURE ? 'https' : 'http'}://${process.env.CUSTOM_HOST}`,
  publicHost: process.env.PUBLIC_HOST || 'https://www.contract.one',
  dsExpireLagMin: process.env.DS_TOKEN_REPLACE_LAG_MIN,
  dsProduction: process.env.DS_PRODUCTION,
  dsClientID: process.env.DS_CLIENT_ID,
  dsClientSecret: process.env.DS_CLIENT_SECRET,
  raClientID: process.env.RA_CLIENT_ID,
  raClientSecret: process.env.RA_CLIENT_SECRET,
  testStyles: process.env.TEST_STYLES,
  deployAllowed: process.env.DEPLOY_ALLOWED,
  allocName: process.env.ALLOC_NAME || 'Contract.one',
  allocSimplawyer: process.env.ALLOC_NAME === 'Simplawyer.one',
  docCacheCfg: process.env.DOC_CACHE || 'generated_files',
  setExperimentalContent: () => _setExperimentalContent(),
  experimentalContent: () => experimental_content,
  db: (() => {
    switch (process.env.CURRENT_DB) {
    case 'production':
      return process.env.PROD_DB;
    case 'development':
      return process.env.DEV_DB;
    case 'testing':
      return process.env.TEST_DB;
    default:
      return process.env.DEV_DB;
    }
  })()
};

