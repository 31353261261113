import React, { useEffect, useState } from 'react';
import styles from "./ruler.css"
import CSSModules from 'react-css-modules';
import { useSelector } from 'react-redux';

const Ruler = () => {

    const {frameProgress, formatting, isInch} = useSelector(state => state.paragraphReducer)
    const sendToFrame = frameProgress?.sendToFrame

    const isTable = formatting ? formatting.isTable : false

    const pxToCm = px => isInch ? Math.round(px.slice(0, px.length - 2)/96 * 10)/10 : Math.round(px.slice(0, px.length - 2)/38 * 10)/10

    const cmToTwips = cm => Math.round(cm*38*15)
    const twipsToRem = twips => {
        // const size = Math.round(twips/20)
        const size = twips/20
        const remSize = (size/7.5).toFixed(3)
        return `${remSize}rem`
    }

    const left = !!formatting ? pxToCm(formatting.marginLeft) : 0
    const right = !!formatting ? pxToCm(formatting.marginRight) : 0
    const indent = !!formatting ? pxToCm(formatting.textIndent) : 0

    const doSpacing = (spacingType, value) => {
        let styleValue = {}
        switch (spacingType) {
            case 'left':
                styleValue = {marginLeft: twipsToRem(cmToTwips((value - 12)/4))}
                break
            case 'right':
                styleValue = {marginRight: twipsToRem(cmToTwips((78 - value)/4))}
                break
            case 'firstLine':
                styleValue = {textIndent: twipsToRem(cmToTwips((value - 12)/4))}
                break
        }
        sendToFrame({c1: "format", paraStyle: styleValue})
    }

    // const isInch = false // Изменение сантиметров на дюймы

    const [leftIndentRange, setLeftIndentRange] = useState(12 + left * 4)
    const [rightIndentRange, setRightIndentRange] = useState(78 - right * 4)
    const [firstLineRange, setFirstLineRange] = useState(12 + indent * 4 + left * 4)

    useEffect(() => {
        setLeftIndentRange(12 + left * 4)
        setRightIndentRange(78 - right * 4)
        setFirstLineRange(12 + indent * 4 + left * 4)
    }, [formatting])

    const arr = [] 
    for (let i = 0; i < 85; i++) {
        arr.push(i)
    }

    const makeNumber = (item) => {
        const num = Math.floor(item / 4) - 2
        if (num === 0 || num === 19) {
            return ""
        }
        return Math.abs(num)
    }

    return (<div styleName={'ruler' + (isTable ? ' disabled' : '')}>
        {arr.map((item, index) => {
            return (index % 2 == 0 ? 
            (index % 4 == 0 ?
                <div key={index} styleName={`item ${isInch ? 'inch' : ''} ${index === 0 || index === 8 || index === 76 || index === 84 ? "long inactive" : ""}`}>
                    {/* <span styleName='number'>{isInch ? item : item - 2}</span> */}
                    <span styleName='number'>
                        {makeNumber(item)}
                    </span>
                </div>
                :
                <div key={index} styleName={`item long ${isInch ? 'inch' : ''}`}></div>)
            : 
            <div key={index} styleName={`item ${isInch ? 'inch' : ''}`}></div>)
        })}
        <input type="range" id="" min="0" max="84" onChange={(e) => {
            doSpacing("left", e.target.value)
            setLeftIndentRange(e.target.value)
        }} value={leftIndentRange} styleName={"slider left-indent" + (isTable ? ' disabled': '')} />
        <input type="range" id="" min="0" max="84" onChange={(e) => {
            setRightIndentRange(e.target.value)
            doSpacing("right", e.target.value)
        }} value={rightIndentRange} styleName={"slider right-indent" + (isTable ? ' disabled': '')} />
        <input type="range" id="" min="0" max="84" onChange={(e) => {
            setFirstLineRange(e.target.value)
            doSpacing("firstLine", e.target.value - leftIndentRange + 12)
        }} value={firstLineRange} styleName={"slider first-line-indent" + (isTable ? ' disabled': '')} />
        <div styleName='inactiveZone right'></div>
        <div styleName='inactiveZone left'></div>
        <div styleName='inactiveZone right filler'></div>
        <div styleName='inactiveZone left filler'></div>
    </div>)
}

export default new CSSModules(Ruler, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});