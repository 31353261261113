import React, { useEffect, useState } from 'react';
import CSSModules from 'react-css-modules';
import styles from "./stylesBlock.css"
import ParagraphOptions from './ParagraphOptions';
import FontOptions from './FontOptions';
import Button from '../../../../components/button/Button';
import { transS } from '../../../../services/helpers/lang';

const StylesBlock = ({params, active, isMain, setActiveStyles}) => {

    const [arrowVisible, setArrowVisible] = useState(false)
    const [currentParams, setCurrentParams] = useState(params)

    useEffect(() => {
        setCurrentParams(params)
    }, [params]) // Позже нужно будет убрать, так как вся информация будет передаваться во фрейм

    const twipsToPt = twips => Math.round(twips/2)/10
    const twipsToCm = cm => Math.round(cm/1.5/38)/10

    const changeParams = el => {
        // console.log(el)
        if (el.font) {
            setCurrentParams(prev => {
                prev.params.currentFont = el.font
                return {...prev}
            })
        }
        if (el.format) {
            if (el.format.fontSize) {
            setCurrentParams(prev => {
                prev.params.fontSize = el.format.fontSize
                return {...prev}
            })}
            if (el.format.fontWeight) {
                setCurrentParams(prev => {
                    prev.params.bold = el.format.fontWeight === "bold"
                    return {...prev}
            })}
            if (el.format.fontStyle) {
                setCurrentParams(prev => {
                    prev.params.italic = el.format.fontStyle === "italic"
                    return {...prev}
            })}
        }
        if (el.paraStyle) {
            if (el.paraStyle.before) {
                setCurrentParams(prev => {
                    prev.params.before = twipsToPt(el.paraStyle.before)
                    return {...prev}
            })
            }
            if (el.paraStyle.after) {
                setCurrentParams(prev => {
                    prev.params.after = twipsToPt(el.paraStyle.after)
                    return {...prev}
            })
            }
            if (el.paraStyle.left) {
                setCurrentParams(prev => {
                    prev.params.left = twipsToCm(el.paraStyle.left)
                    return {...prev}
            })
            }
            if (el.paraStyle.right) {
                setCurrentParams(prev => {
                    prev.params.right = twipsToCm(el.paraStyle.right)
                    return {...prev}
            })
            }
            if (el.paraStyle.hanging) {
                setCurrentParams(prev => {
                    prev.params.hanging = twipsToCm(el.paraStyle.hanging)
                    return {...prev}
            })
            }
            if (el.paraStyle.firstLine) {
                setCurrentParams(prev => {
                    prev.params.firstLine = twipsToCm(el.paraStyle.firstLine)
                    return {...prev}
            })
            }
            if (el.paraStyle.line) {
                setCurrentParams(prev => {
                    prev.params.line = el.paraStyle.line
                    return {...prev}
            })
            }
            if (el.paraStyle.textAlign) {
                setCurrentParams(prev => {
                    prev.params.align = el.paraStyle.textAlign
                    return {...prev}
            })
            }
        }
        
    }

    const sendToFrame = () => {
        console.log(currentParams)
        setActiveStyles(null)
    }

    const cancel = () => {
        setActiveStyles(null)
    }

    return (
        <>
            {isMain ?
                <div styleName={'container full main'} >
                    <div styleName='title'>
                        <div styleName='titleStyles' style={{
                            fontWeight: currentParams.params.bold ? "bold" : "normal",
                            fontStyle: currentParams.params.italic ? "italic" : "normal",
                            fontSize: currentParams.params.fontSize
                        }}>
                            {currentParams.name}
                        </div>
                    </div>
                </div>
            :
                <div styleName={'container' + (active ? " opened" : "") + (currentParams.main ? " mainInList" : "") + (arrowVisible ? " active" : "")} onMouseMove={() => {setArrowVisible(true)}} onMouseLeave={() => {setArrowVisible(false)}}>
                    <div styleName='title'>
                        <div styleName='titleStyles' style={{
                                fontWeight: currentParams.params.bold ? "bold" : "normal",
                                fontStyle: currentParams.params.italic ? "italic" : "normal",
                                fontSize: currentParams.params.fontSize,
                                marginLeft: `${38 * currentParams.params.firstLine}px`
                        }}>
                            {currentParams.name}
                        </div>
                        {(active || arrowVisible) && <div styleName="arrow" className={"arrow" + (active ? " arrowOpen" : "")}></div>}
                        <div styleName={'gradient' + (active || arrowVisible ? ' active' : '')}></div>
                    </div>
                    {active ?  <div styleName="params" onClick={(e) => {e.stopPropagation()}}>
                        <FontOptions fontSize={currentParams.params.fontSize} currentFont={currentParams.params.currentFont} fonts={currentParams.params.fonts} sendToFrame={changeParams} isFormatting={true} isBold={currentParams.params.bold} isItalic={currentParams.params.italic}/>
                        <ParagraphOptions params={currentParams.params} sendToFrame={changeParams}/>
                        <div styleName="buttons">
                            <Button text={transS("Cancel")} mod={"margin0 blue arch blue"} callback={cancel}/>
                            <Button text={transS("Apply")} mod={"fill blue"} callback={sendToFrame}/>
                        </div>
                    </div> : ""}
                </div>
            }
        </>
    );
};

export default new CSSModules(StylesBlock, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});